<template>
    <div class="app-login">
        <v-container px-8 class="dark--text">
            <v-row>
                <v-col cols="12" class="shrink">
                    <h2 class="text-no-wrap text-center my-6">Welcome</h2>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="text-center">
                    <p>
                        Hi {{ firstName }}, it looks like your user hasn't been setup properly. Please get in contact
                        with {{ orgData.title }} to fix the issue.
                    </p>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn block large color="primary" @click="logout">Logout</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
// Services
// import ServiceName from '@/services/service-name';

// Components
// import ComponentName from '@/components/component-name';

// Packages
// import _ from 'lodash';
// import moment from 'moment';

export default {
    name: "welcome",

    props: {},

    components: {},

    data() {
        return {};
    },

    computed: {
        firstName() {
            return (this.$app.user && this.$app.user.firstName) || "";
        },
    },

    methods: {
        logout() {
            this.$fluro.auth.logout();
        },
    },

    async mounted() {},
};
</script>

<style lang="scss">
.new-template {
}
</style>
